/* eslint-disable import/no-cycle */
<template>
  <v-container fluid>
    <template v-if="isRHProtegido">
      <v-row class="ml-7 mt-3" justify="start">
        <v-col cols="12">
          <v-row class="gutter-y-5">
            <v-col v-if="hasAuthorityMovementsMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectMovement" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-search-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular' : 'primaryRH--text mt-3 font-weight-regular'">Movimentações Cadastrais</h5>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="hasAuthorityFamilyGroupInclusionMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectInclusion" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-multiple-plus-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular ' : 'primaryRH--text mt-3 font-weight-regular'" style="word-break: break-word">Inclusão de grupo familiar</h5>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="hasAuthorityDependentInclusionMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectDependentInclusion" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-plus-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular ' : 'primaryRH--text mt-3 font-weight-regular'" style="word-break: break-word">Inclusão de dependente</h5>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="hasAuthorityAlterationMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectAlteration" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-badge-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular ' : 'primaryRH--text mt-3 font-weight-regular'" style="word-break: break-word">Alteração</h5><br>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="hasAuthorityReactivationMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectReactivation" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-arrow-left-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular ' : 'primaryRH--text mt-3 font-weight-regular'" style="word-break: break-word">Reativação</h5><br>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="hasAuthorityExclusionMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectDelete" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-multiple-remove-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular ' : 'primaryRH--text mt-3 font-weight-regular'" style="word-break: break-word">Exclusão</h5><br>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="hasAuthorityImplantationMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectImplantation" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-arrow-up-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular ' : 'primaryRH--text mt-3 font-weight-regular'" style="word-break: break-word">Implantação</h5><br>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>

            <v-col v-if="hasAuthoritySearchInsuredMenu" class="mt-5" cols="6" sm="4" md="3" lg="2">
              <v-hover v-slot:default="{ hover }">
                <v-card @click="redirectSearchInsured" :elevation="hover ? 8 : 2" class="px-5 cardMenuRHProtegido" :color="hover ? 'primaryRH' : '#f2f2f2'">
                  <v-card-title class="text-h6 text-white d-flex flex-column fill-height">
                    <v-row justify="center" align="center">
                      <v-col cols="12" align="center">
                        <v-flex>
                          <v-icon size="40" :color="hover ? 'white' : 'primaryRH'"> mdi mdi-account-search-outline </v-icon>
                          <h5 :class="hover ? 'white--text mt-3 font-weight-regular ' : 'primaryRH--text mt-3 font-weight-regular'" style="word-break: break-word">Pesquisa de Beneficiário</h5><br>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-row v-if="hasNoAuthorities" align="center" justify="center" class="mt-15">
            <v-col align="center" cols="12">
            <v-row justify="center" class="mt-3">
              <v-col cols="12" md="3" lg="3" align="center">
                <v-icon large color="primary">fas fa-low-vision</v-icon>
              </v-col>
            </v-row>

            <v-row justify="center" class="mt-3">
              <v-col cols="12" md="3" lg="3" align="center">
              <p class="text--secondary label">Você não tem permissão para visualizar os menus.</p>
              </v-col>
            </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-if="!isRHProtegido">
      <v-row v-if="hasAuthorityMovementsMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectMovement">
            Movimentações Cadastrais
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasAuthorityImplantationMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectImplantation">
            Implantação
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasAuthorityDependentInclusionMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectDependentInclusion">
            Inclusão de dependente
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasAuthorityAlterationMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectAlteration">
            Alteração
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasAuthorityFamilyGroupInclusionMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectInclusion">
            Inclusão de Grupo Familiar
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasAuthorityReactivationMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectReactivation">
            Reativação
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasAuthorityExclusionMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectDelete">
            Exclusão
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasAuthoritySearchInsuredMenu" justify="center" class="mt-3">
        <v-col cols="12" md="4">
          <v-btn color="primary" block outlined @click="redirectSearchInsured">
            Pesquisa de beneficiário
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasNoAuthorities" align="center" justify="center" class="mt-15">
        <v-col align="center" cols="12">
        <v-row justify="center" class="mt-3">
          <v-col cols="12" md="3" lg="3" align="center">
            <v-icon large color="primary">fas fa-low-vision</v-icon>
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-3">
          <v-col cols="12" md="3" lg="3" align="center">
          <p class="text--secondary label">Você não tem permissão para visualizar os menus.</p>
          </v-col>
        </v-row>
        </v-col>
      </v-row>
    </template>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

    <ButtonPendingMovements
      v-if="isRHProtegido && hasAuthorityMovementsMenu"
      ref="ButtonPendingMovements"
      :isRHProtegido="isRHProtegido"
      @redirectRouter="redirectRouter"
      @setOverlay="setOverlay"
    />
  </v-container>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ButtonPendingMovements from '@/components/CustomAlerts/ButtonPendingMovements.vue';
import { mapMutations } from 'vuex';

export default {
  components: { ButtonPendingMovements },

  data: () => ({
    isRHProtegido: false,
    hasNoAuthorities: false,
    hasAuthorityMovementsMenu: false,
    hasAuthorityImplantationMenu: false,
    hasAuthorityDependentInclusionMenu: false,
    hasAuthorityAlterationMenu: false,
    hasAuthorityFamilyGroupInclusionMenu: false,
    hasAuthorityReactivationMenu: false,
    hasAuthorityExclusionMenu: false,
    hasAuthoritySearchInsuredMenu: false,
    overlay: false,
  }),

  created() {
    this.userUtils = new UserUtils();
  },

  mixins: [
    VerifyRoutesMixin,
  ],

  async mounted() {
    if (this.$route.query && this.$route.query.financialGroupId) {
      sessionStorage.setItem('financialGroupId', `${this.$route.query.financialGroupId}`);
    }
    this.clearSessionStorage();
    this.loadAuthoritiesViewMenus();
    this.verifyAuthorities();
  },

  methods: {
    ...mapMutations({
      setAppOrigin: 'user-info/setAppOrigin'
    }),
    async loadAuthoritiesViewMenus() {
      this.hasAuthorityMovementsMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais' });
      this.hasAuthorityImplantationMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais_visualizar_acao_implantacao' });
      this.hasAuthorityDependentInclusionMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_inclusao_dependente' });
      this.hasAuthorityAlterationMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_alteracao_beneficiario' });
      this.hasAuthorityFamilyGroupInclusionMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_inclusao_grupo_familiar' });
      this.hasAuthorityReactivationMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_reativacao_beneficiario' });
      this.hasAuthorityExclusionMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_exclusao_beneficiario' });
      this.hasAuthoritySearchInsuredMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_pesquisa_beneficiario' });
    },
    verifyAuthorities() {
      if (this.hasAuthorityMovementsMenu
      || this.hasAuthorityImplantationMenu
      || this.hasAuthorityDependentInclusionMenu
      || this.hasAuthorityAlterationMenu
      || this.hasAuthorityFamilyGroupInclusionMenu
      || this.hasAuthorityReactivationMenu
      || this.hasAuthorityExclusionMenu
      || this.hasAuthoritySearchInsuredMenu) {
        this.hasNoAuthorities = false;
      } else {
        this.hasNoAuthorities = true;
      }
    },
    redirectMovement() {
      this.$router.push(`/movement-record?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectImplantation() {
      this.$router.push(`/beneficiary-plan-information?movementType=implantation&isRHProtegido=${this.isRHProtegido}`);
    },

    redirectInclusion() {
      this.$router.push(`/beneficiary-plan-information?movementType=insert&isRHProtegido=${this.isRHProtegido}`);
    },

    redirectAlteration() {
      this.$router.push(`/search-holder-dependent?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectDelete() {
      this.$router.push(`/search-holder-dependent-delete?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectDependentInclusion() {
      this.$router.push(`/dependent-inclusion-search-holders?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectReactivation() {
      this.$router.push(`/reactivation?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectMovementUpload() {
      this.$router.push(`/movement-upload?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectSearchInsured() {
      this.$router.push(`/search-insured?isRHProtegido=${this.isRHProtegido}`);
    },

    clearSessionStorage() {
      const keyToken = '@auth/token';
      const keyUser = '@auth/user';
      // const keyAppOrigin = '@sdi/app_origin';
      const valueToken = sessionStorage.getItem(keyToken);
      const valueUser = sessionStorage.getItem(keyUser);
      sessionStorage.clear();
      sessionStorage.setItem(keyToken, valueToken);
      sessionStorage.setItem(keyUser, valueUser);
      // sessionStorage.setItem(keyAppOrigin, 'PROTECTED_HR');
      this.setAppOrigin('PROTECTED_HR');
    },

    setOverlay(value) {
      this.overlay = value;
    },
  },
};
</script>

<style scoped>
  .v-card {
    transition: opacity .4s ease-in-out;
  }
  .cardMenuRHProtegido {
    height: 190px;
    width: 200px;
    cursor: pointer;
    border-radius: 15px;
  }
</style>
